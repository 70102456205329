<template>
  <Popup ref="popupRef" width="500px" :title="popupTitle" @confirm="confirm" @close="resetForm">
    <div class="btns">
      <div :class="['btn-status', stateType === item.type ? 'btn-fill-theme':'']" v-for="(item, index) in stateList" :key="index" 
      @click="stateType = item.type">{{item.name}}</div>
    </div>
  </Popup>
</template>

<script>
import { editMomentApi, editCommentApi, editTopicApi, editReplyApi, batchEditMomentApi,
batchEditCommentApi, batchEditReplyApi, batchEditTopicApi } from '@/api/admin.js'
import Popup from '@/components/Popup'
export default {
  components: {
    Popup
  },
  data() {
    return {
      stateList: [
        {name:'自己可见', type:1},
        {name:'朋友可见', type:2},
        {name:'公开', type:3},
        {name:'不可见', type:0},
      ],
      stateType: '',
      type: '',
      id: '',
      batchIds: [],
      isBatch: false
    }
  },
  computed: {
    popupTitle() {
      const map = {
        0: '动态',
        1: '话题',
        2: '评论',
        3: '回复'
      }
      return `${this.isBatch ? '批量':''}修改${map[this.type]}状态`
    }
  },
  methods: {
    open(option) {
      this.type = option.type
      this.stateType = option.stateType
      if(option.isBatch) {
        this.batchIds = option.batchIds || []
        this.isBatch = true
      } else {
        this.id = option.id || ''
      }
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetForm() {
      this.isBatch = false
      this.batchIds = []
      this.stateType = ''
      this.type = ''
      this.id = ''
    },
    async confirm() {
      const formData = {
        state: this.stateType
      }
      if(this.isBatch) {
        Object.assign(formData, {modifyId: this.batchIds})
        console.log('formData', formData)
        try {
          let res
          if(this.type == 0) {
            res = await batchEditMomentApi(formData)
          }
          if(this.type == 1) {
            res = await batchEditTopicApi(formData)
          }
          if(this.type == 2) {
            res = await batchEditCommentApi(formData)
          }
          if(this.type == 3) {
            res = await batchEditReplyApi(formData)
          }
          this.$tips({message: res.msg, type: 'success'})
          this.$emit('update')
          this.close()
        } catch (error) {
          this.$tips({message: err, type: 'error'})
        }
      } else {
        try {
          let res
          if(this.type == 0) {
            Object.assign(formData, {mntId: this.id})
            console.log('formData', formData)
            res = await editMomentApi(formData)
          }
          if(this.type == 1) {
            Object.assign(formData, {tpcId: this.id})
            console.log('formData', formData)
            res = await editTopicApi(formData)
          }
          if(this.type == 2) {
            Object.assign(formData, {cmtId: this.id})
            console.log('formData', formData)
            res = await editCommentApi(formData)
          }
          if(this.type == 3) {
            Object.assign(formData, {replyId: this.id})
            console.log('formData', formData)
            res = await editReplyApi(formData)
          }
          this.$tips({message: res.msg, type: 'success'})
          this.$emit('update')
          this.close()
        } catch (err) {
          this.$tips({message: err, type: 'error'})
        }
      }
      
    },
    
  }
}
</script>

<style lang="scss" scoped>
.btns {
  display: flex;
  flex-wrap: wrap;
  .btn-status {
    width: 136px;
    height: 33px;
    line-height: 33px;
    background-color: #F4F4F4;
    color: #001A18;
    border-radius: 4px;
    margin: 0 16px 16px 0;
    text-align: center;
    cursor: pointer;
  }
}
</style>